import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as NodeRSA from 'node-rsa';

function generateKeyPair() {
  const rsa = new NodeRSA();
  rsa.setOptions({ encryptionScheme: 'pkcs1' });
  return rsa.generateKeyPair(2048, 65537);
}

@Injectable({ providedIn: 'root' })
export class RSAHelper {
  private rsa = generateKeyPair();
  public readonly PublicKey!: string;

  constructor() {
    this.PublicKey = this.rsa.exportKey('pkcs1-public-der').toString('hex');
  }

  public decrypt(hexData: string, outputEncode: NodeRSA.Encoding = 'hex'): string {
    return this.rsa.decrypt(Buffer.from(hexData, 'hex'), outputEncode);
  }
}

export const Sign = (data: string | unknown, salt: string, outputEncode = CryptoJS.enc.Hex) => {
  const jsonStr = typeof (data) === 'string' ? data : JSON.stringify(data);
  return CryptoJS.SHA512(jsonStr + salt).toString(outputEncode)
}

export const EncryptRequest = (data: string, key: string, iv: string, format = CryptoJS.enc.Hex) => {
  if (!data) return '';

  return CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Hex.parse(key),
    {
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
      iv: CryptoJS.enc.Hex.parse(iv)
    }
  ).ciphertext.toString(format);
}

export const DecryptResponse = (data: string, key: string, iv: string) => {
  if (!data) return '';
  return CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(data) } as CryptoJS.lib.CipherParams,
    CryptoJS.enc.Hex.parse(key),
    {
      padding: CryptoJS.pad.Pkcs7,
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
    }
  ).toString(CryptoJS.enc.Utf8);
}
