import { v4 } from 'uuid';

export interface ApiRequest<T> {
  RequestDateTime: string;
  RequestID: string;
  Data: T | null;

  Language?: 'vi' | 'en';
}

export interface ApiResponse<T> {
  RequestDateTime: string;
  RequestID: string;
  ResponseCode: string;

  Status?: string;
  Description?: string;
  Data: T;
}

export const buildRequest = <T>(Data: T | null, Language: 'vi' | 'en' = 'vi'): ApiRequest<T> => ({
  RequestDateTime: new Date().toISOString(),
  RequestID: v4(),
  Data,
  Language
})

export const buildFormFileRequest = <T extends File>(Data: T, Language: 'vi' | 'en' = 'vi'): FormData => {
  const formData = new FormData();
  formData.append('fileUpload', Data);
  //formData.append('fileUpload', Data);
  // formData.append('RequestDateTime', new Date().toISOString());
  // formData.append('RequestID', v4());
  // formData.append('Language', Language);
  // formData.append('UserAgent', navigator.userAgent);
  // formData.append('Data', Data);

  return formData;
};